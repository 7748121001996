:root {
	--screenEx: 1390px;
}

.App {
	position: relative;
	background-size: cover;

	min-height: 100vh;
}

.head_three_layer {
	position: absolute;
	z-index: -1;
	background-image: url(assets/FonLL.svg);
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	width: 100%;
	min-height: 800px;
}

.head_two_layer {
	position: absolute;

	width: 100%;
	min-height: 800px;
	z-index: 0;
	opacity: 0.3;
}

.video_head {
	z-index: -2;
	opacity: 0.3;
	object-fit: cover;
	position: absolute;
	max-height: 800px;
	height: 100%;
	width: 100%;
}

.head {
	max-width: var(--screenEx);
	min-height: 800px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 59px 135px;

	border-bottom: 1px solid #4a4a4a9e;

}

.container {
	max-width: 1300px;
}

.center_content {
	background-image: url(assets/fon_bottom.svg);

	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	padding: 0 135px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.portfolio_page {
	height: 100%;
	min-height: 100vh;
	width: 100%;
	background-image: url(assets/fon_bottom.svg);
	display: flex;
	flex-direction: column;
	align-items: center;

	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;

	padding: 165px 135px;
	z-index: 1;
}

.portfolio_page__title {
	max-width: 520px;
	max-height: 92px;
}

.portfolio_page__subtitle {
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 135%;
	/* or 27px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.75;
	margin: 57px 0;
}

.project_case {
	height: 278px;
}

.portfolio_page__case {
	max-width: 552px;
	max-height: 401px;
	width: 100%;
	object-fit: cover;
	cursor: pointer;

	border-radius: 11px;
	position: relative;
}

.portfolio_page__case_active {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* gap: 10%; */
  gap: 3%;
	width: 100%;
	max-width: 847px;
	height: 100%;
	position: absolute;
	top: 0;
	background: linear-gradient(
			103.17deg,
			rgba(37, 20, 89, 0.9) 4.34%,
			rgba(5, 60, 73, 0.9) 100%
	);
	border-radius: 11px;
}

.portfolio_page__cases {
	display: flex;
	justify-content: space-between;
}

.portfolio_page__cases1 {
	display: flex;
	flex-direction: column;
	gap: 60px;
	margin-right: 60px;
	margin-bottom: 60px;
}

.portfolio_page__cases2 {
	display: flex;
	flex-direction: column;
	gap: 60px;
	margin-bottom: 60px;
}

@media screen and (max-width: 1200px) {
	.portfolio_page__cases {
		flex-direction: column;
	}

	.portfolio_page__cases1 {
		margin-right: 0;
	}
}

.toPortfolio {
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  /* or 27px */

  letter-spacing: -0.04em;

  color: #ffffff;

  opacity: 0.75;

  text-decoration: underline;

  width: 100%;
  cursor: pointer;
  margin: 50px 0;
}

.wrapper_center {
	display: flex;
	justify-content: center;
}

.wrapper_maxWidth {
	max-width: var(--screenEx);
	width: 100%;
}

.header {
	max-width: 1665px;

	position: absolute;
	z-index: 10000000000;
	width: 100%;
	padding: 59px 135px;
	align-items: center;
	display: flex;
	justify-content: space-between;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	text-align: center;
	letter-spacing: -0.025em;

	color: rgba(255, 255, 255, 0.6);
}

.intend {
	margin-top: 140px;
}

.navbar_btn {
	text-decoration: none;
	background: transparent;
	border: none;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	letter-spacing: -0.025em;
	color: rgba(255, 255, 255, 0.6);
}

.navbar_btn:hover {
	text-decoration: none;
	transition: 1s;
	color: rgba(255, 255, 255);
	text-shadow: 0 0 15px rgba(255, 253, 253, 0.615);
}

.icon_git_hub:hover path {
	opacity: 1 !important;
	transition: 1s;
	fill: white;
}

.icon_git_hub:hover {
	filter: drop-shadow(0 0 5px rgba(255, 253, 253, 0.615));
}

.icon_telegram:hover path {
	opacity: 1 !important;
	transition: 1s;
	fill: white;
}

.icon_telegram:hover {
	filter: drop-shadow(0 0 5px rgba(255, 253, 253, 0.615));
}

.navbar {
	gap: 40px;
	display: flex;
}

.btn_head_main_mobile {
	position: absolute;
	top: -9999px;
	left: -9999px;

}

.header__main_btn {
	display: flex;
	align-items: center;
	gap: 25px;
}

.btn_hire_us {
	position: relative;
	width: 101px;
	height: 50px;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: -0.025em;

	color: #ffffff;

	border-radius: 10px;
}

.btn_hire_us__text {
	margin-top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 90%;
	position: absolute;
}

.btn_hire_us__border {
	height: 100%;
	position: absolute;
}

.btn_hire_us__fill {
	height: 100%;
	position: absolute;
	opacity: 0;
}

.btn_hire_us:hover .btn_hire_us__border {
}

.btn_hire_us:hover .btn_hire_us__fill {
	height: 100%;
	justify-content: center;
	justify-items: center;
	border-radius: 50%;
	box-shadow: 0 0 25px 5px #00feed45;
	z-index: -1;
	transition: 0.5s;
	opacity: 1;
	display: block;
}

.btn_hire_us:hover {
	transition: 1s;
}

.btn_language {
	width: 57px;
	height: 30px;

	border: 1px solid #ffffff9e;
	border-radius: 15px;
	background: transparent;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
	text-align: center;
	letter-spacing: -0.025em;

	color: #ffffff9e;

	opacity: 0.6;
}

.btn_language_active {
	width: 57px;
	height: 30px;

	border: 1px solid #ffffff;
	border-radius: 15px;
	background: transparent;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
	text-align: center;
	letter-spacing: -0.025em;

	color: #ffffff;

	opacity: 1;
}

.btn_language path {
	opacity: 1;
}

.btn_language:hover path {
	opacity: 1;
	transition: 1s;
}

.btn_language:hover {
	transition: 1s;
	opacity: 1;
	color: #ffffff;
	border: 1px solid #ffffff;
}

.for_btn_language {
	position: relative;
}

.btn_language:hover ~ .for_modal_language {
	transition: 0.5s;
	opacity: 1;
	display: block;
}

/* ~ .btn_language  */

.for_modal_language:hover {
	opacity: 1;
	display: block;
}

.for_modal_language {
	position: absolute;
	width: 104px;
	height: 134px;
	right: 0;
	opacity: 0;
	display: none;
}

.modal_language {
	display: flex;
	flex-direction: column;
	justify-content: center;


	margin-top: 23px;

	width: 104px;
	height: 114px;
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(255, 255, 255, 0.28);
	border-radius: 11px;
}

.btn_language_choise:hover {
	transition: 0.5s;
	opacity: 1;
}

.btn_language_choise {
	display: flex;
	justify-content: center;
	height: 30px;
	border: none;
	background: transparent;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	align-items: center;
	text-align: center;
	letter-spacing: -0.025em;

	color: #ffffff;

	opacity: 0.6;
}

.left_opp_letters {
	position: absolute;
	left: 0;
	top: 1000px;
}

.head__title {
	height: 100%;
	padding-top: 80px;
	gap: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.head__main_text {
	margin-top: -50px;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 64px;
	line-height: 135%;
	/* or 86px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;

	color: #ffffff;
}

.head__bottom {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.icon_social_networks {
	display: flex;
	gap: 30px;
}

.head__bottom-left-btn {
	background: transparent;
	border: none;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.6;
}

.opportunities_line_gor {
	display: none;
}

.navbar_menu {
	margin-top: 27px;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 27px;
}

@media screen and (max-width: 600px) {
	.head__bottom {
		height: 100px;
	}

	.header__main_btn {
		display: none;
	}

	.head__main_text {
		font-size: 24px !important;
	}

	.opportunities__main_text {
		font-size: 20px !important;
	}

	.opportunities__submain_text {
		font-size: 12px !important;
	}

	.portfolio_page__subtitle {
		font-size: 16px !important;
	}

	.text_stack__text_name {
		font-size: 12px !important;
	}

	.text_stack__text_value {
		font-size: 12px !important;
	}

	.stack_block__line_gorizont {
		gap: 79px !important;
		margin-top: 79px !important;
	}

	.text_stack__text_row {
		height: 79px !important;
	}

	.stack_block {
		height: 300px !important;
		margin-bottom: 50px;
	}

	.form_connection {
		width: 100% !important;
		margin: 25px 14px 15px 14px !important;
	}

	.active_slider_work {
		font-size: 12px !important;
		line-height: 150% !important;
	}

	.active_slider_work__btn {
		font-size: 12px !important;
		width: 60% !important;
		height: auto !important;
		min-height: 50px;
	}

	.block_input {
		margin-bottom: 30px;
	}

	.form_connection__inputs {
		gap: 0 !important;
	}

	.form_connection__input {
		height: 41px !important;
	}

	.form_connection__textarea-block {
		margin-top: 0 !important;
	}

	.form_connection__textarea {
		height: 119px !important;
	}
}

.menu {
	position: absolute;
	top: 0;
	right: 0;

	width: 200px;
	height: 388px;
	background: #0a0d0f;
	border: 1px solid rgba(255, 255, 255, 0.33);
	border-bottom-left-radius: 14px;
}

.menu__content {
	padding: 70px 42px 20px 42px;
	position: relative;
}

.menu__btn-close-menu {
	right: 25px;
	position: absolute;
	top: 45px;
	z-index: 10000;
	cursor: pointer;
}

.icon_social_networks_menu {
	display: flex;
	gap: 21px;
}

.for_main_btn_modal {
	display: flex;
	justify-content: center;
	padding-left: 9px;

	align-items: center;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	width: 100%;
	height: 80px;
}

.menu,
.btn-open-menu {
	display: none;
}

@media screen and (max-width: 850px) {
	.head_three_layer {
		background-image: url(assets/fon_head_mobile.svg);
	}

	.btn_head_main_mobile {
		top: 0;
		left: 0;
		position: relative !important;
		display: flex !important;
	}

	.center_content {
		background-image: url(assets/fon_bottom_mobile.svg) !important;
	}

	.menu,
	.btn-open-menu {
		display: block;
	}

	.for_modal_language {
		top: 15px;
		left: 0;
	}

	.modal_language {
		background: #0a0d0f;
		border: 1px solid rgba(255, 255, 255, 0.28);
	}

	.btn_language {
		border: 1px solid #ffffff;
		color: #ffffff;
	}

	.navbar_btn {
		text-decoration: none;
		font-family: "Syne", sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 135%;
		/* or 19px */

		display: flex;
		align-items: center;
		text-align: center;
		margin-left: -5px;
		letter-spacing: -0.025em;

		color: rgba(255, 255, 255, 0.8);
	}

	.navbar {
		display: none;
	}

	.left_opp_letters {
		display: none;
	}

	.opportunities {
		padding-top: 70px !important;
	}

	.center_content {
		padding: 0 16px;
	}

	.head,
	.header {
		padding: 41px 16px;
	}

	.portfolio_page {
		padding: 165px 16px;
	}

	.head__main_text {
		font-size: 35px;
	}

	.header__main_btn {
		display: none;
		flex-direction: column;
		position: absolute;
		right: 10%;
	}

	.header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		gap: 30px;
		font-family: "Syne", sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 135%;
		/* or 19px */

		text-align: center;
		letter-spacing: -0.025em;

		color: rgba(255, 255, 255, 0.6);
	}
}

@media screen and (max-width: 1100px) {
	.arrow_btn_next_slaid,
	.arrow_btn_prev_slaid {
		display: none !important;
	}

	.swiper-pagination {
		display: block !important;
	}
}

@media screen and (max-width: 1350px) {
	.btn_works_prev {
		left: -10% !important;
	}

	.btn_works_next {
		right: -10% !important;
	}
}

@media screen and (max-width: 1250px) {

	.left_opp_letters {
		top: 950px;
	}

	.opportunities {
		/* aic */
		flex-direction: column;
	}

	.opportunities_line_gor {
		overflow: hidden;
		width: 100%;
		margin: 70px 0;
		display: block;
	}

	.opportunities__blocks {
		width: 100% !important;
	}

	.opportunities_line {
		display: none;
	}

	.opportunities__text {
		width: 100% !important;
	}

	.block_form_connection__image {
		display: none;
	}

	.form_connection__inputs {
		width: 100%;
	}

	.form_connection {
		width: 100% !important;
	}

	.block_input {
		width: 40%;
	}

	.form_connection__input {
		padding-left: 19px;
		background: transparent;
	}
}

.swiper-slide {
	display: flex;
	justify-content: center;
	align-items: center;
}

.opportunities {
	display: flex;
	min-height: 700px;
	padding-top: 73px;
}

.opportunities__text {
	display: flex;
	flex-direction: column;
	width: 320px;
	gap: 20px;
}

.opportunities__main_text {
	margin-bottom: 20px;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 135%;
	/* or 32px */

	display: flex;
	align-items: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.8;
}

.opportunities__submain_text {
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 144%;
	/* or 20px */

	display: flex;
	align-items: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.6;
}

.opportunities_line {
	margin: 0 100px;
}

.opportunities__block {
	width: 297px;
	height: 252px;

	background: rgba(255, 255, 255, 0.05);
	border-radius: 11px;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.technical_block {
	margin-top: -25px;
}

.left_block {
	margin-right: 45px;
}

.opportunities__block_title {
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;

	opacity: 0.75;
	margin-bottom: 29px;
}

.opportunities__block_title__svg {
}

.technical_block_mobile {
	display: none !important;
}

@media screen and (max-width: 700px) {
	/* title block------------------- */
	.title_blockchain_apps {
		width: 110px;
	}

	.title_smart_contracts {
		width: 102px;
	}

	.title_marketplaces {
		width: 115px;
	}

	.title_service {
		width: 110px;
	}

	.title_blockchain_integration {
		width: 140px;
	}

	.title_landing {
		width: 91px;
	}

	.title_browser_games {
		width: 158px;
	}

	/* ------------------- */
	.technical_block {
		display: none !important;
	}

	.technical_block_mobile {
		display: block !important;
	}

	.opportunities__block_icon {
		margin: 23px 0 5px 0 !important;
	}

	.opportunities__block_title {
		display: flex;
		justify-content: center;
		align-items: start;
		height: 32px;
		font-size: 12px !important;
		margin-bottom: 0;
	}

	.opportunities__block_text {
		display: flex;
		justify-content: start;
		align-items: flex-start !important;
		text-align: center;
		height: 55px;
		font-size: 11px !important;

		overflow: hidden;
	}

	.opportunities__two_blocks {
		display: flex;
		flex-direction: row !important;
		justify-content: center;
		gap: 1% !important;
	}

	.opportunities__block {
		padding: 0 10px;
		width: 168px;
		height: 168px;
	}
}

.opportunities__block_text {
	max-width: 200px;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 125%;
	/* or 17px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.6;
}

.opportunities__btn_next {
	display: block;
	z-index: 1000000000000;
	border: #0073fa 4px solid;
	width: 100px;
	height: 50px;
	position: absolute;
}

.opportunities__block_icon {
	margin: 29px 0 20px 0;
}

.opportunities__blocks {
	margin-top: -50px;
	width: 60%;
	display: flex;
	justify-content: space-between;

	flex-wrap: wrap;
	align-items: center;
}

.opportunities__two_blocks {
	display: flex;
	flex-direction: column;
	gap: 46px;
}

.arrow_btn_prev_slaid {
	border: none;
	background: transparent;
	position: absolute;
	left: 3%;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.arrow_btn_prev_slaid_arrow {
	z-index: 3;
	margin-top: -3px;
	position: absolute;
}

.arrow_btn_prev_slaid:hover .border_btn_slaid_active {
	opacity: 1;
}

.arrow_btn_prev_slaid:hover ~ .border_btn_slaid_active {
	display: block;
	opacity: 1;
}

.arrow_btn_next_slaid {
	border: none;
	background: transparent;
	position: absolute;
	right: 3%;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.arrow_btn_next_slaid_arrow {
	z-index: 3;
	margin-top: -3px;
	position: absolute;
}

.border_btn_slaid_active {
	border: none;
	transition: 0.8s;
	background: transparent;
	position: absolute;
	margin-top: -3px;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.arrow_btn_next_slaid:hover .arrow_btn_next_slaid_border {
	/* you can add faded stroke */
}

.arrow_btn_next_slaid:hover .border_btn_slaid_active {
	opacity: 1;
}

.arrow_btn_next_slaid:hover ~ .border_btn_slaid_active {
	display: block;
	opacity: 1;
}

.text_acquainted {
	margin-top: 100px;

	text-align: right;
}

.text_stack {
	padding: 29px 0 59px 0;
}

.text_acquainted_mobile,
.text_stack_mobile {
	display: none;
}

@media screen and (max-width: 450px) {
	.text_stack {
		padding-top: 15px;
	}

	.text_acquainted_mobile {
		display: flex;
		margin-top: 50px;
		justify-content: end;
	}

	.text_acquainted {
		display: none;
	}
}

.for_slider_works {
	width: 100%;
	display: flex;
	justify-content: center;
}

.swiper-pagination-bullet-active {
	background: linear-gradient(
			301.37deg,
			#0073fa -15.13%,
			#00feef 109.56%
	) !important;
}

.swiper-pagination-bullet {
	background-color: rgb(219, 217, 217) !important;
	opacity: 0.5;
}

.slider_works {
	position: relative;
	display: flex;
	align-items: center;
	margin: 50px 0;
	width: 100%;
	padding: 10px 0;

	background: rgba(255, 255, 255, 0.05);
	border-radius: 11px;
}

.slider_works__swiper {
	width: 100%;
	height: 100%;
}

.slider_works__slide {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.slider_works__img {
	/* w */
	width: 90%;
	max-height: 420px;
	max-width: 845px;
	object-fit: cover;
	cursor: pointer;

	border-radius: 11px;
	position: relative;
}

.slider_works__img_active {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10%;
	width: 90%;
	max-width: 847px;
	height: 100%;
	position: absolute;

	background: linear-gradient(
			103.17deg,
			rgba(37, 20, 89, 0.9) 4.34%,
			rgba(5, 60, 73, 0.9) 100%
	);
	border-radius: 11px;
}

.slider_works__img_active--portfolio {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10%;

	width: 90%;
	max-width: 847px;
	height: 100%;
	position: absolute;

	background: linear-gradient(
			103.17deg,
			rgba(37, 20, 89, 0.9) 4.34%,
			rgba(5, 60, 73, 0.9) 100%
	);
	border-radius: 11px;
}

.active_slider_work {
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	/* or 22px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;
	line-height: 280%;

	color: #ffffff;

	opacity: 0.9;
}

.active_slider_work__btn {
	cursor: pointer;
	width: 356px;
	height: 78px;

	border: none;
	background: transparent;
	border-radius: 39px;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 20px;
	line-height: 135%;
	/* or 27px */
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: -0.04em;

	color: #ffffff;
}

.active_slider_work__btn_border {
	display: flex;
	position: absolute;
}

.stack_block {
	position: relative;
	height: 600px;
}

.stack_block__line_gorizont {
	width: 100%;
	margin-top: 158px;
	display: flex;
	flex-direction: column;
	gap: 158px;
	position: absolute;
}

.stack_block_li_v {
	width: 1px;
	height: 100%;
	background: -moz-linear-gradient(
			left,
			rgba(30, 87, 153, 0) 0%,
			rgba(215, 215, 215, 0.98) 50%,
			rgb(202, 202, 202) 51%,
			rgba(225, 228, 226, 0) 99%,
			rgba(229, 229, 229, 0) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
			left,
			rgba(30, 87, 153, 0) 0%,
			rgba(198, 200, 199, 0.98) 50%,
			rgb(174, 174, 174) 51%,
			rgba(225, 228, 226, 0) 99%,
			rgba(229, 229, 229, 0) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
			to bottom,
			rgba(30, 87, 153, 0) 0%,
			rgba(255, 255, 255, 0.681) 50%,
			rgba(255, 255, 255, 0.753) 51%,
			rgba(225, 228, 226, 0) 99%,
			rgba(229, 229, 229, 0) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.stack_block_li_g {
	width: 100%;
	height: 1px;
	background: -moz-linear-gradient(
			left,
			rgba(30, 87, 153, 0) 0%,
			rgba(215, 215, 215, 0.98) 50%,
			rgb(202, 202, 202) 51%,
			rgba(225, 228, 226, 0) 99%,
			rgba(229, 229, 229, 0) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
			left,
			rgba(30, 87, 153, 0) 0%,
			rgba(198, 200, 199, 0.98) 50%,
			rgb(174, 174, 174) 51%,
			rgba(225, 228, 226, 0) 99%,
			rgba(229, 229, 229, 0) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
			to right,
			rgba(30, 87, 153, 0) 0%,
			rgba(255, 255, 255, 0.681) 50%,
			rgba(255, 255, 255, 0.753) 51%,
			rgba(225, 228, 226, 0) 99%,
			rgba(229, 229, 229, 0) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.stack_block__line_ver {
	left: 26%;

	position: absolute;
	height: 85%;
}

.text_stack__text_col {
	width: 100%;
	/* left: 10%; */
	position: absolute;
	display: flex;
	flex-direction: column;
}

.text_stack__text_row {
	height: 158px;
	width: 100%;

	display: flex;
	justify-content: space-between;
}

.text_stack__text_name {
	width: 24%;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.75;
}

.text_stack__text_value {
	width: 65%;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: start;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.75;
}

.title_form_connection {
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 800;
	font-size: 24px;
	line-height: 135%;
	/* or 32px */

	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.8;
}

.block_form_connection {
	display: flex;
	margin: 57px 0;
	width: 100%;
	/* height: 586px; */
	background: rgba(255, 255, 255, 0.05);
	border-radius: 11px;
}

.block_form_connection__image {
	width: 35%;
	height: 596px;

	background: url(assets/ImageForm.svg) no-repeat;
	background-size: cover;
	border-radius: 11px 0 0 11px;
}

.form_connection {
	width: 65%;
	/* padding: 57px ; */
	margin: 55px 59px 55px 59px;
}

.block_input {
	width: 45%;
	position: relative;
}

.swiper-container {
	padding: 40px 0 !important;
}

.opportunities_swiper {
	min-width: 630px;
	padding: 40px 0 !important;
}

.swiper-pagination {
	display: none;
	position: absolute !important;
	margin-bottom: 0 !important;
}

.swiper-pagination {
}

.main_logo {
	cursor: pointer;
}

@media screen and (max-width: 630px) {
	.opportunities_swiper {
		min-width: 0;
	}
}

@media screen and (max-width: 550px) {
	.block_input {
		width: 48%;
		position: relative;
	}

	.for_btn_сontact_us {
		margin-top: 25px !important;
	}
}

.star_input {
	margin-top: -15px;
	width: 12px;
	text-align: right;
}

.name_input {
	margin-bottom: 13px;
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 135%;
	/* or 16px */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.6;
}

.form_connection__inputs {
	display: flex;
	justify-content: space-between;
	gap: 32px;
	flex-wrap: wrap;
}

.form_connection__input {
	padding-left: 19px;
	background: transparent;
	width: 100%;
	height: 50px;
	border: 1px solid #ffffff3a;
	border-radius: 8px;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.8;
}

.form_connection__textarea {
	padding: 19px 21px;
	resize: none;

	width: 100%;
	height: 134px;
	background: transparent;

	border: 1px solid #ffffff3a;
	border-radius: 8px;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	letter-spacing: -0.04em;

	color: #ffffff;

	opacity: 0.8;
}

.form_connection__textarea-block {
	position: relative;
	margin-top: 32px;
}

.for_btn_сontact_us {
	margin-top: 45px;
	width: 100%;
	display: flex;
	justify-content: end;
}

.btn_сontact_us:hover .btn_сontact_us__fill {
	transition: 0.5s;
	opacity: 1;
}

.btn_сontact_us__text {
	opacity: 0.6;
	margin-top: -5px;
	z-index: 5;
	position: absolute;
}

.btn_сontact_us__fill {
	opacity: 0;
	position: absolute;
}

.btn_сontact_us {
	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: -0.025em;

	color: #ffffff;
	border-radius: 10px;
}

.page_work {
	position: absolute;
	top: 0;
	width: 100%;
	height: auto;
	overflow: hidden;
}

.page_work_img {
	width: 100%;
	height: 100%;

	position: relative;
	z-index: 1;
}

@media screen and (max-width: 1155px) {
	.page_work_img {
		margin-top: 60px;

	}
}

.btn_close_page {
	cursor: pointer;
	right: 5%;
	top: 5%;
	border: #0363d0be 3px solid;
	position: fixed;

	width: 101px;
	height: 50px;
	background: #041222;

	font-family: "Syne", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 135%;
	/* or 19px */

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	letter-spacing: -0.025em;
	color: #ffffff;
	border-radius: 15px;

	text-decoration-line: none;
	margin-top: 70px;

	z-index: 4;
}

.btn_close_page:hover {
	transition: 1s;
	background: #0363d0;
	transform: scale(1.2);
}

.btn_close_page:hover ~ .page_work_img {

}

.center_header{
	display: grid;
	justify-items: center;

	position: relative;
	z-index: 3;
	height: 50px;

}
