.content {
	position: relative;
	background-size: cover;

	min-height: 100vh;
}

.wrapper_maxWidth {
	//max-width: var(--screenEx);
	width: 100%;
	display: flex;
	justify-content: center;
}
