.svg_icon_git_hub {
  width: 17px;
  height: 20px;
}
.svg_icon_telegram {
  width: 24px;
  height: 20px;
}
.svg_head_submain_text {
  width: 415px;
  height: 44px;
}
.svg_icon_opp {
  width: 56px;
  height: 56px;
}
.svg_text_after_works {
  width: 100%;
  height: auto;
  max-width: 575px;
  min-width: 343px;
}
.svg_text_after_opp {
  width: 100%;
  height: auto;
  max-width: 758px;
  cursor: pointer;
}
.svg_text_after_opp_mobile {
  width: 100%;
  height: auto;
}
.svg_text_after_opp_mobile {
  display: flex;
  justify-content: end;
}
.svg_border_btn_contact_us {
  width: 164px;
  height: 50px;
}
.svg_border_btn_contact_us_active {
  width: 224px;
  height: 110px;
}
.svg_btn_works_border {
  width: 356px;
  height: 78px;
}
.svg_get_in_touch {
  width: 386px;
  height: 19px;
}
@media screen and (max-width: 700px) {
  .svg_icon_opp {
    width: 29px;
    height: 38px;
  }
}
@media screen and (max-width: 850px) {
  .svg_icon_git_hub {
    width: 11px;
    height: 12px;
  }
  .svg_icon_telegram {
    width: 14px;
    height: 12px;
  }
}
@media screen and (max-width: 600px) {
  .svg_get_in_touch{
    width: 268px;
    /* height: 19px; */
  }
  .svg_head_submain_text {
    width: 233px;
    height: 20px;
  }
  .svg_border_btn_contact_us {
    width: 124px;
    height: 43px;
  }
  .svg_border_btn_contact_us_active {
    width: 170px;
    height: 103px;
  }
  .svg_btn_works_border {
    width: 183px;
    height: 37px;
  }
}
