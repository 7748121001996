

.content{
  height: 500px;

  display: grid;
  grid-auto-flow: row;
  row-gap: 18px;
  align-content: start;
  justify-content: center;

  margin-top: 75px;
  @media screen and (max-width: 1190px){
    display: none;
  }

}

.currencies, .blockchains{
  width: 1170px;
  height: 189px;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 11px;

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  column-gap: 17px;
}

.blockchains{
  column-gap: 13px;
}

.title, .titleBlockchains{
  font-family: 'Syne', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 135%;

  letter-spacing: -0.04em;

  color: #FFFFFF;
  opacity: 0.8;

  margin-left: 80px;
  margin-right: 44px;
}

.titleBlockchains{
  margin-right: 20px;
}

.currency{
  display: grid;
  grid-auto-flow: row;
  row-gap: 10px;
  align-items: center;
  justify-items: center;

  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  text-align: center;

  color: #FFFFFF;

  margin-top: -8px;
}

.other{
  font-family: 'Syne', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 125%;
  /* or 16px */

  text-align: center;
  letter-spacing: -0.04em;

  color: #FFFFFF;

  opacity: 0.6;

  margin-top: -22px;

  span{
    position: relative;
    top: 1px;
  }
}

.warning{
  box-sizing: border-box;

  width: 541px;
  height: 67px;

  background: rgba(0, 255, 133, 0.25);
  border: 1px solid #00FF85;
  border-radius: 11px;


  display: grid;
  align-items: center;
  justify-self: end;
  grid-auto-flow: column;


  padding-left: 20px;
  div{
    font-family: 'Syne', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;

    letter-spacing: -0.04em;

    color: #FFFFFF;

    margin-left: -46px;
  }
}

