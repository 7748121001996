.content {
	position: relative;
	background-size: cover;

	min-height: 100vh;
}

.portfolio_page__cases1 {
	display: flex;
	flex-direction: column;
    align-items: center;
	gap: 60px;
	margin-right: 60px;
	margin-bottom: 60px;
}

.portfolio_page__cases2 {
	display: flex;
	flex-direction: column;
    align-items: center;
	gap: 60px;
	margin-bottom: 60px;
}

.wrapper_maxWidth {
	//max-width: var(--screenEx);
	width: 100%;
	display: flex;
	justify-content: center;
}
